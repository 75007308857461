*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background: white;
    width: 100vw;
    height: 100vh;
}
.navbar-nav {
    margin-left: auto;
}
nav{

    background: url("https://images.unsplash.com/photo-1525490829609-d166ddb58678?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    z-index: 999;
}
.dropdown-item {
    font-size: 18px !important;
}

.nav-link{
    color :white !important;
    font-weight: bold;
}
.navbar-brand{color:white !important;
    font-weight: bold;
text-decoration: underline;}
.navbar-brand{
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
}
#navbarDropdown,#navbarDropdown2{
    background: transparent;
    border: none;
    outline: none;
}
@media screen and (max-width:570px) {
    .navbar-brand{
        font-size: 15px;
    }
}