@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins";
 
}
.contact_center{
    text-align: center;
}

#container {
background: white;
}
.container form{
  height: 330px;
}

 #contact_profile{
   border-radius: 50%;
 }
.clickme{
    background: grey;
    width: 150px;
    color: white;
}
#cntct{
    color: #152238;
    font-weight: bolder;
    text-transform: uppercase;
}

#logo{
  width:125px;
  padding: 3px;
  height: 55px;
}

#contactForm{
  display: flex;
  background: white;
  justify-content: space-around;
  align-items: center;
}
.contactImg{
  width: 100%;
  height: 70%;
}
.contactImg img{
  width: 800px;
  height: 500px;
}

@media screen and (max-width:1052px) {

#contactForm{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

#logo{
  width:65px;
  padding: 1px;
  height: 35px;
}
.contactImg img{
  width:100%;
  margin-top: 50px;
  height: 100%;
}

}
