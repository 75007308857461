@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background: white;
    width: 100vw;
    height: 100vh;
}
.navbar-nav {
    margin-left: auto;
}
nav{

    background: url("https://images.unsplash.com/photo-1525490829609-d166ddb58678?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    z-index: 999;
}
.dropdown-item {
    font-size: 18px !important;
}

.nav-link{
    color :white !important;
    font-weight: bold;
}
.navbar-brand{color:white !important;
    font-weight: bold;
text-decoration: underline;}
.navbar-brand{
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
}
#navbarDropdown,#navbarDropdown2{
    background: transparent;
    border: none;
    outline: none;
}
@media screen and (max-width:570px) {
    .navbar-brand{
        font-size: 15px;
    }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins";
 
}
.contact_center{
    text-align: center;
}

#container {
background: white;
}
.container form{
  height: 330px;
}

 #contact_profile{
   border-radius: 50%;
 }
.clickme{
    background: grey;
    width: 150px;
    color: white;
}
#cntct{
    color: #152238;
    font-weight: bolder;
    text-transform: uppercase;
}

#logo{
  width:125px;
  padding: 3px;
  height: 55px;
}

#contactForm{
  display: flex;
  background: white;
  justify-content: space-around;
  align-items: center;
}
.contactImg{
  width: 100%;
  height: 70%;
}
.contactImg img{
  width: 800px;
  height: 500px;
}

@media screen and (max-width:1052px) {

#contactForm{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

#logo{
  width:65px;
  padding: 1px;
  height: 35px;
}
.contactImg img{
  width:100%;
  margin-top: 50px;
  height: 100%;
}

}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}
body{
    height: 100vh;
}
.banner{
    height: 520px;
    display: grid;
    margin-top: 5px;
    place-items: center;
    background-repeat: no-repeat;
     background-size: 100% 100%;
     -webkit-animation: carousel 18s ease-out  infinite;
             animation: carousel 18s ease-out  infinite;
}
.banner h3{
    color: white;
    text-align: center;
    font-size: 35px;
    justify-content: center;
}
.clients{
    width: 100vw;
    height: auto;
    margin: 20px 0px;
    display: flex;
   align-items: center;
   justify-content: center;
}

.card-main{
    height: 300px;
    width: 25%;
    background: white;
    transition: all 0.5s ease-in-out;
    font-weight: bolder;
    flex-grow: 2;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.card-1{
    height: 300px;
    width: 25%;
    border: 1px solid white;
    background: #58c62c;
    background-size: 100% 100%;
    font-size: 1.2rem;
    flex-grow: 1;
    transition: all 0.5s ease-in-out;
}
.card-2{

    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
   padding-top: 5px;
    grid-row-gap: 25px;
    row-gap: 25px;
    align-items: center;
    flex-grow: 1;
    transition: all 0.5s ease-in-out;
}

.card-main h1{
    margin-top: 10%;
    text-align: center;
    font-size: 30px;

    word-wrap: break-word;
    padding: 10px;
}
.card-1 h2 {
    color: white;
    padding-top: 25px;
   text-align: center;
}
.card-1 p {
    padding-top: 55px;
    text-align: center;
    color: white;
}

.card-2 h2 {
text-align: justify;
font-weight: bold;
}
.card-2 p {
    padding-left:10px ;
word-wrap: break-word;
text-align: justify;
height: 100%;

}
.card-main p{
    padding-top: 55px;
   text-align: center;
}
.card-1:hover{
   
    background-color: black;
    font-size: 1.5rem;

}
.content_Main{
        padding: 5px 5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        background: #EEEEEE;
        
        grid-row-gap: 50px;
        
        row-gap: 50px;
        height: 450px;
}

.content_Main img{
    width: 600px;

}
.content_Main2 img{
    width: 400px;

}
.content_Main h2{
    color: black;
}

.content_Main2 h2{
    color: black;
}
.card-main:hover{
    background: #58c62c;
    transition: all 0.5s ease-in-out;
    color: white;
}
@media screen and (max-width:952px) {
    .card-2{
           font-size: 1.2rem;
           display: flex;
           flex-direction: column;
          padding-top: 5px;
           grid-row-gap: 25px;
           row-gap: 25px;
           align-items: center;
           flex-grow: 1;
           transition: all 0.5s ease-in-out;
       }
    .card-main h1{
        font-size: 35px;
        font-weight: bold;
        line-height: 35px;
    }
    .clients{
        width: 100vw;
        height: auto;
        display: flex;
       align-items: center;
       flex-direction: column;
       justify-content: center;
    }
    .card-1{
        width: 100%;
    }
    .card-main{
        width: 100%;
    }
    #banner_image_car{
        height: 420px !important;
    }
    .banner{
        width: 100vw;
       height: 420px !important;
    }
    .content_Main{
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
    }
    .content_Main img{
        width: 100%;
    }
    
    .card-1 h2{
        width: 100%;
    }
    .card-2 h2{
        width: 100%;

    }
    .banner h3{
        font-size: 25px;
    }
}
/* @keyframes carousel {
    0%{
        background-image: url('./projectphoto/1.jpg');
    }
    25%{
     background-image: url('./projectphoto/3.jpg');
    }
    50%{
        background-image: url('./projectphoto/2.jpeg');
    }
    75%{
        background-image: url('./projectphoto/4.jpg');
    }
    100%{
        background-image: url('./projectphoto/5.jpg');
    }
    
} */
.Distributor{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 500px;
    background-color:#EEEEEE;
}
.Distributor img,.invertorImg{
    height: 300px;
    width:350px;
    transition: all 0.5s ease-in-out;
}
.Distributor_Details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
}
.Distributor_Details_h2{
    color: black;
}
.Distributor_button{
    width: 120px;
    padding: 10px;
    border: none;
    outline: none;
    background:#333;
    font-weight: bold;
    border-radius: 10px;
}
.Distributor_button a{
    text-decoration: none;
    color: white;
}
.Distributor img:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.invertorBg{
    width: 100vw;
    height: 600px;
    background-size: 100% 100%;
}
.container{
    margin-top: 50px;
}
@media screen and (max-width:952px) {
    .Distributor img,.invertorImg{
        width:80%;
        padding: 25px;
    }
    .invertorBg{
        width: 100vw;
        height: 400px;
        background-size: 100% 100%;
    }
    .Distributor_Details_h2{
       text-align: center;
    }
}
.Media_pics{
    display: flex;
    width:100%;
    align-items:center ;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}
.Media_pics img{
    width:85%;
    height: 500px;
    padding:5px;
    margin-bottom: 20px;
}
@media screen and (max-width:952px) {
    .Media_pics img{
        width: 100%;
        height: 300px;
    }
}
.MainContent{
    width: 100%;
    height: 100%;
}
.container{
    background: transparent;
}
.Product_Header{
    width: 100%;
    color: white;
    text-align: center;
    align-items: center;
    font-weight: bold;
}
.Products_Details{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
}

.Products_Details img{
    width:35%;
    height: 400px;
    border-radius: 10px;
}
.Products_Details p{
    margin-top: 15px;
    width:45%;
    height: 400px;
    color: white;
    text-align: justify;
    font-size: 1.1rem;
}
.v-list-v23 li{
color: white;
text-align: justify;
}
.v-list-v23 h3{
    color: white;
}
.v-lead_main{
    color: white;
    text-align: justify;
}
.Product_Img{
    height: 400px;
    width:400px;
    border-radius: 10px;
    padding-bottom: 5px;
    transition: all 0.5s ease-in-out;
}
@media screen and (max-width:952px) {
   .Product_Img{
        width:100%;
        padding:2px 15px;
    }
    .Products_Details{
        
        flex-wrap: wrap;
        flex-direction: column;
    }
    .Products_Details img{
        width:95%;
        height: 400px;
        border-radius: 10px;

    }
    .Product_Header{
        padding-top: 10px;
    }
    .Products_Details p{
        width: 100%;
        font-size: 1rem;
        padding: 5px;
        height: auto;
    }
}
#product_secondaary_text{
    color: white;
    text-align: justify;
}
.v-spacer h2{
    color: white;
}
.About_Us{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    
}
.About_Us_I{
    width: 95%;
    text-align: justify;
    height:450px;
}

.About_Us_I p{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    margin-top: 15px;
    font-weight: 500;
    padding: 20px 0px;
    line-height: 31px;
    color: white;
    
}
#contact{
    margin-top: 50px;
  
  }
  
.contact_center{
    text-align: center;
}

.container_about {
  margin-top: 50px;
}

 #contact_profile{
   border-radius: 50%;
 }
.container_about .team {
  width: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.container_about .team .member {
  width: 325px;
  margin: 10px;
  background: #ddd;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  padding: 25px;
height: auto;}

#contact_profile{
  width: 150px;
}

.container_about .team .member h3 {
  color: #444;
}

.container_about .team .member span {
  font-size: 12px;
  color: #999;
}

.container_about .team .member p {
  margin: 15px 0;
  font-weight: 400;
  color: #999;
  font-size: 15px;
  text-align: justify;
}

.container_about .team .member .btn a {
  background: #fafbfc;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  float: right;
  width: 125px;
  margin: 0 10px;
  padding: 10px;
  border-radius: 6px;
  color: #444;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.container_about .team .member .btn a:hover {
  background: #5a36dd;
  color: #fff;
  text-decoration: none;
}
@media screen and (max-width:952px){
    .About_Us{
        flex-direction: column;
    }
    .About_Us_I{
        width: 100%;
        text-align: justify;
    }
 
}
.footer_main{
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    flex-direction: row;
    background:#343a40 ;
    padding: 50px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.footer_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 33%;
}
.footer_content_h2{
    color: white;
    font-weight: bold;
}
.footer_content a{
    text-decoration:none;
    border: none;
    padding: 3px;
    color: white;
    transition: all 0.5s ease-in-out;
}
.footer_content p{
    margin-bottom: 0;
    text-decoration:none;
    border: none;
    color: white;
    transition: all 0.5s ease-in-out;
}
.enquire_btn{
    background: #58c62c;
    transition: all 0.5s ease-in-out;
    color: white;
    border: none;
    outline: none;
    padding: 15px;
    border-radius: 10px;
}
.enquire_btn a{
    color:black;
    text-decoration: none;
}
.enquire_btn:hover{
    background: white;
    color: black;
}
.footer_content a:hover{
    color: black;
    padding:5px;
    font-size: 1.3rem;
}
.footer_company_logo{
    width: 107px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-size: 100% 100%;
}
@media screen and (max-width:572px) {
    .footer_content a{
        font-size: 0.9rem;
    }
    .footer_main{
        flex-direction: column;
        height: 100%;
      }
          .footer_content{
              width: 100%;
              margin: 5px;
          }
          .enquire_btn{
              width:120px;
              padding: 10px;
          }
          .footer_content p{
              font-size: 17px;
          }
}

