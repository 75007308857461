.MainContent{
    width: 100%;
    height: 100%;
}
.container{
    background: transparent;
}
.Product_Header{
    width: 100%;
    color: white;
    text-align: center;
    align-items: center;
    font-weight: bold;
}
.Products_Details{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
}

.Products_Details img{
    width:35%;
    height: 400px;
    border-radius: 10px;
}
.Products_Details p{
    margin-top: 15px;
    width:45%;
    height: 400px;
    color: white;
    text-align: justify;
    font-size: 1.1rem;
}
.v-list-v23 li{
color: white;
text-align: justify;
}
.v-list-v23 h3{
    color: white;
}
.v-lead_main{
    color: white;
    text-align: justify;
}
.Product_Img{
    height: 400px;
    width:400px;
    border-radius: 10px;
    padding-bottom: 5px;
    transition: all 0.5s ease-in-out;
}
@media screen and (max-width:952px) {
   .Product_Img{
        width:100%;
        padding:2px 15px;
    }
    .Products_Details{
        
        flex-wrap: wrap;
        flex-direction: column;
    }
    .Products_Details img{
        width:95%;
        height: 400px;
        border-radius: 10px;

    }
    .Product_Header{
        padding-top: 10px;
    }
    .Products_Details p{
        width: 100%;
        font-size: 1rem;
        padding: 5px;
        height: auto;
    }
}
#product_secondaary_text{
    color: white;
    text-align: justify;
}
.v-spacer h2{
    color: white;
}