.Media_pics{
    display: flex;
    width:100%;
    align-items:center ;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}
.Media_pics img{
    width:85%;
    height: 500px;
    padding:5px;
    margin-bottom: 20px;
}
@media screen and (max-width:952px) {
    .Media_pics img{
        width: 100%;
        height: 300px;
    }
}