.About_Us{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    
}
.About_Us_I{
    width: 95%;
    text-align: justify;
    height:450px;
}

.About_Us_I p{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    margin-top: 15px;
    font-weight: 500;
    padding: 20px 0px;
    line-height: 31px;
    color: white;
    
}
#contact{
    margin-top: 50px;
  
  }
  
.contact_center{
    text-align: center;
}

.container_about {
  margin-top: 50px;
}

 #contact_profile{
   border-radius: 50%;
 }
.container_about .team {
  width: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.container_about .team .member {
  width: 325px;
  margin: 10px;
  background: #ddd;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  padding: 25px;
height: auto;}

#contact_profile{
  width: 150px;
}

.container_about .team .member h3 {
  color: #444;
}

.container_about .team .member span {
  font-size: 12px;
  color: #999;
}

.container_about .team .member p {
  margin: 15px 0;
  font-weight: 400;
  color: #999;
  font-size: 15px;
  text-align: justify;
}

.container_about .team .member .btn a {
  background: #fafbfc;
  display: block;
  text-decoration: none;
  border: none;
  outline: none;
  float: right;
  width: 125px;
  margin: 0 10px;
  padding: 10px;
  border-radius: 6px;
  color: #444;
  text-transform: capitalize;
  transition: all 0.6s ease;
}

.container_about .team .member .btn a:hover {
  background: #5a36dd;
  color: #fff;
  text-decoration: none;
}
@media screen and (max-width:952px){
    .About_Us{
        flex-direction: column;
    }
    .About_Us_I{
        width: 100%;
        text-align: justify;
    }
 
}