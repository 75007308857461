*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}
body{
    height: 100vh;
}
.banner{
    height: 520px;
    display: grid;
    margin-top: 5px;
    place-items: center;
    background-repeat: no-repeat;
     background-size: 100% 100%;
     animation: carousel 18s ease-out  infinite;
}
.banner h3{
    color: white;
    text-align: center;
    font-size: 35px;
    justify-content: center;
}
.clients{
    width: 100vw;
    height: auto;
    margin: 20px 0px;
    display: flex;
   align-items: center;
   justify-content: center;
}

.card-main{
    height: 300px;
    width: 25%;
    background: white;
    transition: all 0.5s ease-in-out;
    font-weight: bolder;
    flex-grow: 2;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.card-1{
    height: 300px;
    width: 25%;
    border: 1px solid white;
    background: #58c62c;
    background-size: 100% 100%;
    font-size: 1.2rem;
    flex-grow: 1;
    transition: all 0.5s ease-in-out;
}
.card-2{

    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
   padding-top: 5px;
    row-gap: 25px;
    align-items: center;
    flex-grow: 1;
    transition: all 0.5s ease-in-out;
}

.card-main h1{
    margin-top: 10%;
    text-align: center;
    font-size: 30px;

    word-wrap: break-word;
    padding: 10px;
}
.card-1 h2 {
    color: white;
    padding-top: 25px;
   text-align: center;
}
.card-1 p {
    padding-top: 55px;
    text-align: center;
    color: white;
}

.card-2 h2 {
text-align: justify;
font-weight: bold;
}
.card-2 p {
    padding-left:10px ;
word-wrap: break-word;
text-align: justify;
height: 100%;

}
.card-main p{
    padding-top: 55px;
   text-align: center;
}
.card-1:hover{
   
    background-color: black;
    font-size: 1.5rem;

}
.content_Main{
        padding: 5px 5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        background: #EEEEEE;
        
        row-gap: 50px;
        height: 450px;
}

.content_Main img{
    width: 600px;

}
.content_Main2 img{
    width: 400px;

}
.content_Main h2{
    color: black;
}

.content_Main2 h2{
    color: black;
}
.card-main:hover{
    background: #58c62c;
    transition: all 0.5s ease-in-out;
    color: white;
}
@media screen and (max-width:952px) {
    .card-2{
           font-size: 1.2rem;
           display: flex;
           flex-direction: column;
          padding-top: 5px;
           row-gap: 25px;
           align-items: center;
           flex-grow: 1;
           transition: all 0.5s ease-in-out;
       }
    .card-main h1{
        font-size: 35px;
        font-weight: bold;
        line-height: 35px;
    }
    .clients{
        width: 100vw;
        height: auto;
        display: flex;
       align-items: center;
       flex-direction: column;
       justify-content: center;
    }
    .card-1{
        width: 100%;
    }
    .card-main{
        width: 100%;
    }
    #banner_image_car{
        height: 420px !important;
    }
    .banner{
        width: 100vw;
       height: 420px !important;
    }
    .content_Main{
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
    }
    .content_Main img{
        width: 100%;
    }
    
    .card-1 h2{
        width: 100%;
    }
    .card-2 h2{
        width: 100%;

    }
    .banner h3{
        font-size: 25px;
    }
}
/* @keyframes carousel {
    0%{
        background-image: url('./projectphoto/1.jpg');
    }
    25%{
     background-image: url('./projectphoto/3.jpg');
    }
    50%{
        background-image: url('./projectphoto/2.jpeg');
    }
    75%{
        background-image: url('./projectphoto/4.jpg');
    }
    100%{
        background-image: url('./projectphoto/5.jpg');
    }
    
} */